const fetcher = (...args: [RequestInfo, RequestInit | undefined]) =>
  fetch(...args).then((res) => {
    if (!res.ok) throw new Error(res.status.toString());
    return res.json();
  });
export default fetcher;

export const gqlFetcher = async (query: string) => {
  const url = `${process.env.NEXT_PUBLIC_CRAFT_CMS_URL}/api`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {accept: 'application/json', 'Content-Type': 'application/json'},
    body: JSON.stringify({ operationName: null, query, variables: null }),
  });

  const data = await res.json();
  return data;
};

